import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlbaranPendiente,
  setCamiones,
  setObras,
  setTipos,
} from "../redux/actions";
import { savePreferences } from "../pluggins/albaranes";
import { Detector } from "react-detect-offline";
import {
  fetchCamiones,
  fetchObras,
  fetchPreferences,
  fetchTipos,
} from "../services/albaranes";
import https from "../pluggins/http";
import queryString from "query-string";
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import QRCode from "react-qr-code";

const DetallesObra = ({ location }) => {
  async function setUser() {
    try {
      const response = await https.post("/getConductor", {
        id: query.idConductor,
      });
      if (response?.data?.data) {
        setUsuario(response?.data?.data);
        userSet(true);
      }
    } catch (e) {}
  }
  let history = useHistory();
  const [isUserSet, userSet] = useState(false);
  const query = queryString.parse(location.search);
  const dispatch = useDispatch();
  const [user, setUsuario] = useState({});
  const rol = useSelector((state) => state?.auth?.currentUser?.rol);
  const aux = useSelector((state) => state?.auth?.currentUser?.data);
  const camiones = useSelector((state) =>
    Array.isArray(state?.app?.camiones) ? state?.app?.camiones : []
  );
  const obras = useSelector((state) =>
    Array.isArray(state?.app?.obras) ? state?.app?.obras : []
  );
  const tipos = useSelector((state) =>
    Array.isArray(state?.app?.tipos) ? state?.app?.tipos : []
  );
  const [isLoading, setLoading] = useState(true);
  const [m3DataMounted, setm3DataMounted] = useState(false);
  const [m3Data, setm3Data] = useState("");
  const [obrasid, setObrasid] = useState("");
  const [tiposId, setTiposId] = useState("");
  const [codigo_LER, setCodigo_LER] = useState("");
  const [camion_id, setIdCamiones] = useState("");
  const [matricula, setMatricula] = useState("");
  const [conductor_id, setconductor_id] = useState("");
  const [isMounted, setisMounted] = useState(false);
  const [isObraMounted, setisObraMounted] = useState(false);
  const [, setidExterno] = useState([]);
  const [tiposResiduos, setTiposResiduos] = useState([]);
  const [creating, setCreating] = useState(false);
  const [preferences, setDriverPreferences] = useState();

  const [obraComodin, setObraComodin] = useState(null);
  const [isVisible, setVisible] = useState("none");
  const [isTiposVisible, setTiposVisible] = useState("block");

  const override = jsx`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const _handlePreferences = async () => {
    setLoading(true);
    const payload = {
      conductor_id: conductor_id,
      camion_id: camion_id,
      obra_id: obrasid,
      residuo_id: tiposId,
    };

    if (window.navigator.onLine) {
      await _processPreferences(payload);
    } else {
      setLoading(false);
    }
  };

  const _processPreferences = async (payload) => {
    try {
      console.log("EL TIPO A ENVIAR ES: ", payload?.residuo_id);
      if (!tiposResiduos.includes(payload?.residuo_id)) {
        payload.residuo_id = tiposResiduos[0];
      }

      // Verifica si isVisible es "block" y obraComodin no tiene un nombre
      if (isVisible === "block" && !obraComodin) {
        alert("El nombre de la obra comodín es obligatorio.");
        setLoading(false);
        setCreating(false);
        return; // Sale de la función si obraComodin no tiene un nombre
      }

      if (isVisible === "block") {
        if (obraComodin != null) {
          try {
            const idObra = await https.post("createObraComodin", {
              nombre: obraComodin,
              operador: user.operador,
              active: 0,
            });
            payload.obra_id = idObra;
          } catch (error) {
            setLoading(false);
            setCreating(false);
            return; // Sale de la función si hay un error al crear la obra comodín
          }
        }
      }

      const response = await savePreferences(payload);
      alert("Preferencias guardadas con éxito");
      window.location.reload();
      setLoading(false);
      setCreating(false);
    } catch (e) {
      setCreating(false);
      alert(e.response.data.message);
      setLoading(false);
    }
  };

  const handleSetObras = useCallback(
    (data = []) => {
      setidExterno(data[data?.length - 1]?.conductores?.split(",") || []);
      let founded = false;
      for (let i = 0; i < data.length; i++) {
        if (
          (data[i]?.operador == aux?.operador ||
            ("" + data[i].conductores).split(",").indexOf("" + aux.id) > -1) &&
          data[i].active === 0
        ) {
          setObrasid(data[i].obra_id);
          return;
        } else {
        }
      }
    },
    [setidExterno, setObrasid]
  );

  const getObras = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchObras({ operador: aux?.operador });
        if (Array.isArray(response)) {
          dispatch(setObras(response));
          handleSetObras(response);
        }
      } catch (_error) {
        handleSetObras(obras);
      }
    } else {
      handleSetObras(obras);
    }
  }, [dispatch, handleSetObras, obras]);

  const getTipos = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchTipos();
        if (Array.isArray(response)) {
          dispatch(setTipos(response));
        }
      } catch (_error) {}
    }
  }, [dispatch, tipos]);

  const handleSetCamiones = useCallback(
    (data = []) => {
      if (!m3DataMounted) {
        const finded = data?.find(
          (option) => option.id === user?.camion_asignado
        );
        if (finded) {
          setMatricula(finded.matricula);
          setm3DataMounted(true);
        }
      }
      setIdCamiones(user?.camion_asignado);
    },
    [m3DataMounted, user.camion_asignado]
  );

  const getCamiones = useCallback(async () => {
    if (window.navigator.onLine) {
      try {
        const response = await fetchCamiones({ id: user?.operador });
        if (Array.isArray(response)) {
          dispatch(setCamiones(response));
          handleSetCamiones(response);
        }
      } catch (_error) {
        handleSetCamiones(camiones);
      }
    } else {
      handleSetCamiones(camiones);
    }
  }, [user, dispatch, handleSetCamiones, camiones]);

  const tiposRef = useRef("");

  const handleSetPreferences = async () => {
    setLoading(true);
    let response = await fetchPreferences(user?.id);

    console.log("LA RESPONSE", response);
    setIdCamiones(
      response?.data?.camion
        ? response?.data?.camion?.id
        : user?.camion_asignado
    );

    if (response?.data?.obra?.id) {
      setObrasid(response?.data?.obra?.id);

      setTiposResiduos(response?.data?.obra?.residuos_fijos?.split(","));
    }

    if (response?.data?.tipo_residuo?.id) {
      setTiposId(response?.data?.tipo_residuo?.id);
    }

    setDriverPreferences(response?.data);
  };

  const handleTiposchange = (e) => {
    setTiposId(e.target.value);
    tipos.map((option) =>
      "" + option.id === "" + e.target.value
        ? setCodigo_LER(option.codigo_ler)
        : null
    );
  };

  // const handleSetTipos = (e) => {
  //   console.log("entra aqui macho");
  //   setTiposId(e);
  //   tipos.map((option) =>
  //     "" + option.id === "" + e ? setCodigo_LER(option.codigo_ler) : null
  //   );
  // };
  console.log("OBRAS", obras);

  const filteredOptions = useMemo(() => {
    return obras.filter((option) => {
      const isUserConductor = ("" + option.conductores)
        .split(",")
        .includes("" + user.id);
      const isOperador = option.operador === user.operador;
      const isActive = option.active === 0;
      const isObraComodin = option.comodin;
      const matchPreferencia = option.obra_id === obrasid;

      return (
        (!isObraComodin && (isOperador || isUserConductor) && isActive) ||
        (isObraComodin && matchPreferencia)
      );
    });
  }, [obras, user.id, user.operador, obrasid]);

  const toggleVisible = (obra) => {
    if (obra === "OBRA COMODIN") {
      setVisible("block");
      setTiposVisible("none");
    } else {
      setVisible("none");
      setTiposVisible("block");
    }
  };

  const handleObrasChange = (e) => {
    setObrasid(e.target.value);
    obras.map((option) =>
      "" + option.obra_id === "" + e.target.value
        ? (option.residuos_fijos
            ? setTiposResiduos(option.residuos_fijos.split(","))
            : setTiposResiduos([]),
          toggleVisible(option.obra_nombre))
        : null
    );
  };

  const handleMatriculaChange = (e) => {
    const selectedId = parseInt(e.target.value, 10);

    const selectedCamion = camiones.find((option) => option.id === selectedId);

    if (selectedCamion) {
      setIdCamiones(selectedId);
      setm3Data(selectedCamion.capacidad);
    }
  };

  const generateQRContent = () => {
    if (!preferences) return "";
    const qrPayload = {
      conductor_id: preferences.conductor.id,
      conductor: preferences.conductor.name,
      m3_Kg: preferences.camion.capacidad,
      codigo_LER: preferences.tipo_residuo.codigo_ler,
      camion: preferences.camion.matricula,
      obra_id: preferences.obra.id,
      obra: preferences.obra.name,
      tipo_residuo_id: preferences.tipo_residuo.id,
      tipo_residuo: preferences.tipo_residuo.name,
      operador: preferences.conductor.operador.nombre,
      operador_id: preferences.conductor.operador.id,
    };

    return JSON.stringify(qrPayload);
  };

  useEffect(() => {
    // Verifica si el usuario está configurado
    if (!isUserSet) {
      if (rol === "vertedero") {
        setUser();
      } else {
        setUsuario(aux);
        userSet(true);
      }
    }

    // Ejecuta estas funciones solo una vez cuando el componente se monta y el usuario está configurado
    if (!isMounted && isUserSet) {
      const initializeComponent = async () => {
        setLoading(true);
        await getObras();
        await getCamiones();
        setconductor_id(user?.id);
        await getTipos();

        // Maneja las preferencias del conductor
        const response = await fetchPreferences(user?.id);
        if (response?.data) {
          setDriverPreferences(response?.data);

          // Configura los camiones, obras y tipos de residuos basados en la respuesta de las preferencias
          setIdCamiones(
            response?.data?.camion
              ? response?.data?.camion?.id
              : user?.camion_asignado
          );

          if (response?.data?.obra?.id) {
            setObrasid(response?.data?.obra?.id);
            setTiposResiduos(response?.data?.obra?.residuos_fijos?.split(","));
          }

          if (response?.data?.tipo_residuo?.id) {
            setTiposId(response?.data?.tipo_residuo?.id);
          }
        }
        setTimeout(3000);
        setLoading(false);
        setisMounted(true);
      };

      initializeComponent();
    }
  }, [
    isMounted,
    isUserSet,
    rol,
    aux,
    user?.id,
    setUser,
    setUsuario,
    userSet,
    getObras,
    getCamiones,
    getTipos,
    fetchPreferences,
    setconductor_id,
    setDriverPreferences,
    setIdCamiones,
    setObrasid,
    setTiposResiduos,
    setTiposId,
    setLoading,
  ]);

  useEffect(() => {
    if (obrasid !== null) {
      const selectedObra = obras.find((option) => option.obra_id === obrasid);
      if (selectedObra && selectedObra.comodin) {
        setTiposVisible("none");
      } else {
        setTiposVisible("block");
      }
    }
  }, [obrasid, obras]);

  return (
    <div>
      <div className="header">
        <Header />
      </div>
      <form>
        {isLoading ? (
          <div className="containerPrincipalDO">
            <div className="loader-container">
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader
                  css={override}
                  size={50} // Tamaño del loader
                  color={"#123abc"} // Color del loader
                  loading={true}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="containerPrincipalDO">
            <div className="containerPopupDO">
              <div>
                <p className="SubTitulo">Selecciona la Obra</p>

                <select
                  name="seleccObraName"
                  onChange={handleObrasChange}
                  className="selectColor selectSizer"
                  disabled={isLoading}
                  value={obrasid}
                >
                  {filteredOptions.map((option) => (
                    <option key={option.obra_id} value={option.obra_id}>
                      {option.obra_nombre}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ display: isVisible }}>
                <p className="SubTitulo">Nombre y datos de la obra</p>
                <input
                  placeholder="Nombre de la obra"
                  className="inputs comentario"
                  onChange={(e) => setObraComodin(e.target.value)}
                  value={obraComodin}
                  disabled={isLoading}
                />
              </div>
              <div
                style={{
                  display:
                    isTiposVisible === "none"
                      ? "block"
                      : isVisible === "block"
                      ? "block"
                      : "none",
                }}
                className="aviso--offline"
              >
                Al seleccionar una obra comodín como obra preferida, se
                seleccionará por defecto "Tierra" como tipo de residuo
                preferido.
              </div>
              <div
                style={{
                  display:
                    isTiposVisible === "block"
                      ? isVisible === "block"
                        ? "none"
                        : "block"
                      : "none",
                }}
              >
                <div>
                  <p className="SubTitulo">Tipo de Residuo</p>
                  <select
                    name="tipoResiduo"
                    onChange={handleTiposchange}
                    className="selectColor selectSizer"
                    disabled={isLoading}
                    ref={tiposRef}
                    value={tiposId}
                  >
                    {tipos.map((option, i) => {
                      if (
                        tiposResiduos.length === 0 ||
                        tiposResiduos.includes(option.id.toString())
                      ) {
                        return (
                          <option value={option.id} key={"key" + i}>
                            {option.nombre} - {option.codigo_ler}
                          </option>
                        );
                      }
                      return "";
                    })}
                  </select>
                </div>
              </div>
              <div>
                <p className="SubTitulo">Matricula</p>
                <select
                  name="matricula"
                  className="selectColor selectSizer"
                  onChange={handleMatriculaChange}
                  disabled={isLoading}
                  value={camion_id}
                >
                  {camiones.map((option, i) => (
                    <option
                      key={i} // Usa el índice como key
                      value={option.id}
                    >
                      {option.matricula}
                    </option>
                  ))}
                </select>
              </div>

              <div className="detector--contaier">
                <Detector
                  render={({ online }) =>
                    !online && (
                      <div className="aviso--offline">
                        Estás sin conexión a internet, el albarán de entrega se
                        creará cuando tengas conexión a internet disponible
                      </div>
                    )
                  }
                />
              </div>

              <div className="btn">
                <button
                  type="button"
                  className="txtBtnCreaAlbaran"
                  onClick={() => {
                    _handlePreferences();
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="sk-chase">
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                      <div className="sk-chase-dot"></div>
                    </div>
                  ) : (
                    "Generar QR"
                  )}
                </button>
              </div>
              <div style={{ textAlign: "center", marginTop: 100 }}>
                <QRCode value={generateQRContent()} size={250} level="Q" />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default DetallesObra;
